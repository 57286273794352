<template>
  <main id="main">
    <CampaignBannerAlt v-if="isCampaign" v-bind="campaignHero" />
    <PageHeaderStudy v-else v-bind="hero">
      <InlineSearch
        id="couse-search"
        name="s"
        label="Search for a course"
        placeholder="Find a course"
        action="/find/"
        with-submit
        dark
      />
      <p class="paragraph-sm shim-mt1 shim-mb0">Popular searches:</p>
      <ul class="list-inline paragraph-sm">
        <li>
          <a href="/find/?s=Science">Science</a>
        </li>
        <li>
          <a href="/find/?s=Business">Business</a>
        </li>
        <li>
          <a href="/find/?s=Bachelor%20of%20Design">Bachelor of Design</a>
        </li>
        <li>
          <a href="/find/?s=Criminology">Criminology</a>
        </li>
      </ul>
    </PageHeaderStudy>
    <PathfinderToday :items="pathfinderLinks" title-two-lines>
      <template v-if="isCampaign" #before>
        <div class="grid grid--lg shim-mb1">
          <div class="cell cell--lg cell--tab-1of2 shim-mt0 shim-mb1">
            <InlineSearch
              id="couse-search"
              name="s"
              label="Search for a course"
              placeholder="Find a course"
              action="/find/"
              with-submit
            />
          </div>
          <div class="cell cell--lg cell--tab-1of2 shim-mt0 shim-mb0">
            <p class="paragraph-sm shim-mb0">Popular searches:</p>
            <ul class="list-inline paragraph-sm">
              <li>
                <a href="/find/?s=Science">Science</a>
              </li>
              <li>
                <a href="/find/?s=Business">Business</a>
              </li>
              <li>
                <a href="/find/?s=Bachelor%20of%20Design">Bachelor of Design</a>
              </li>
              <li>
                <a href="/find/?s=Criminology">Criminology</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </PathfinderToday>
    <template v-for="(section, sectionIndex) in sections">
      <SectionAlt
        v-if="section.component === 'SectionAlt'"
        :key="sectionIndex"
        :img-src="section.imgSrc"
      >
        <template v-if="section.type !== '3-up'" #left>
          <h2 class="heading-md">
            {{ section.heading }}
          </h2>
          <MarkupToday
            v-if="section.introduction"
            :html="section.introduction"
          />
          <ButtonIcon
            v-if="section.cta"
            :href="section.cta.link"
            class="btn--text"
          >
            {{ section.cta.text }}
          </ButtonIcon>
        </template>
        <template v-if="section.type === 'video'">
          <VideoPlayer v-if="section.video" v-bind="section.video" />
        </template>
        <template v-else-if="section.type === 'columns'">
          <div
            v-if="section.columns && section.columns.length"
            class="grid grid--lg"
          >
            <div
              v-for="(column, colIndex) in section.columns"
              :key="colIndex"
              class="cell cell--lg cell--tab-1of2 content-max-width shim-mt0 shim-mb0 shim-mb1--desk"
            >
              <h3 class="heading-sm shim-mb033">
                {{ column.heading }}
              </h3>
              <MarkupToday :html="column.content" />
            </div>
          </div>
        </template>
        <template v-else-if="section.type === 'testimonials'">
          <template v-if="section.testimonials && section.testimonials.length">
            <div
              v-if="section.testimonialType === 'video'"
              class="grid grid--lg grid--scrollable"
            >
              <div
                v-for="(testimonial, testimonialIndex) in section.testimonials"
                :key="testimonialIndex"
                class="cell cell--lg cell--tab-1of2 shim-mb0 shim-mt0"
              >
                <CardPortrait
                  :title="testimonial.title"
                  :description="testimonial.name"
                  :img-src="testimonial.image_url"
                  :video="testimonial.video"
                  autoplay
                />
              </div>
            </div>
            <div
              v-else-if="section.testimonialType === 'long'"
              class="shim-mb1"
            >
              <TestimonialsAlt
                v-for="(testimonial, testimonialIndex) in section.testimonials"
                :key="testimonialIndex"
                :title="testimonial.title"
                :name="testimonial.name"
                :img-src="testimonial.image_url"
                long
              />
            </div>
            <div
              v-else-if="section.testimonialType === 'text'"
              class="grid grid--lg"
            >
              <div
                v-for="(testimonial, testimonialIndex) in section.testimonials"
                :key="testimonialIndex"
                class="cell cell--lg cell--tab-1of2 shim-mt0 shim-mb1"
              >
                <TestimonialsAlt
                  :title="testimonial.title"
                  :name="testimonial.name"
                  :img-src="testimonial.image_url"
                />
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="section.type === 'content'">
          <MarkupToday :html="section.content" />
          <div
            v-if="section.additionalColumns && section.additionalColumns.length"
            class="grid grid--lg shim-mt2"
          >
            <div
              v-for="(column, colIndex) in section.additionalColumns"
              :key="colIndex"
              class="cell cell--lg cell--tab-1of2 shim-mt0 content-max-width shim-mb1"
            >
              <img
                v-if="column.imgSrc"
                role="presentation"
                alt=""
                :src="column.imgSrc"
                class="display-block shim-mb067"
              />
              <h4 class="heading-xs shim-mb033">
                {{ column.heading }}
              </h4>
              <MarkupToday :html="column.content" />
            </div>
          </div>
        </template>
        <template v-if="section.type === '3-up'">
          <h2 v-if="section.heading" class="heading-md shim-mb2">
            {{ section.heading }}
          </h2>
          <div
            v-if="section.columns && section.columns.length"
            class="grid grid--lg"
          >
            <div
              v-for="(column, index) in section.columns"
              :key="index"
              class="cell cell--lg cell--tab-1of3 shim-mb0 shim-mt0 content-max-width"
            >
              <img
                v-if="column.imgSrc"
                role="presentation"
                alt=""
                :src="column.imgSrc"
                class="display-block shim-mb067"
              />
              <h3 class="heading-sm">
                {{ column.heading }}
              </h3>
              <MarkupToday :html="column.content" />
            </div>
          </div>
        </template>
      </SectionAlt>
      <SectionImage
        v-else-if="section.component === 'SectionImage'"
        :key="sectionIndex"
        :img="section.img"
      >
        <h2 class="heading-md shim-mb2" style="max-width: 15rem">
          {{ section.heading }}
        </h2>
        <QuickLinksAlt :items="section.links" alt no-inset class="shim-mb1" />
      </SectionImage>
      <VideoFullWidth
        v-else-if="section.component === 'VideoFullWidth' && section.video"
        :key="sectionIndex"
        v-bind="section.video"
      />
    </template>
  </main>
</template>

<script>
import {
  InlineSearch,
  CampaignBannerAlt,
  PathfinderToday,
  PageHeaderStudy,
  ButtonIcon,
  VideoFullWidth,
  SectionAlt,
  SectionImage,
  VideoPlayer,
  TestimonialsAlt,
  CardPortrait,
  QuickLinksAlt,
} from '@unimelb/pattern-lib-vue';
import MarkupToday from '~/components/today/markup/MarkupToday.vue';

export default {
  components: {
    PathfinderToday,
    PageHeaderStudy,
    CampaignBannerAlt,
    InlineSearch,
    ButtonIcon,
    MarkupToday,
    QuickLinksAlt,
    VideoFullWidth,
    SectionAlt,
    SectionImage,
    VideoPlayer,
    TestimonialsAlt,
    CardPortrait,
  },
  data() {
    return {
      basePath: process.env.basePath,
      hero: {
        title: 'Start your life of learning',
        bgSrc: 'https://pattern-lib-unimelb.netlify.app/study-bg.jpg',
        bgColorSrc:
          'https://pattern-lib-unimelb.netlify.app/study-bg-color.jpg',
        img: {
          src: 'https://pattern-lib-unimelb.netlify.app/study-bg-subject.png',
        },
      },
      campaignHero: {
        title: 'Register now for Open Days 2021',
        img: {
          src: 'https://pattern-lib-unimelb.netlify.app/campaign-hero-mobile.jpg',
          alt: 'Woman typing on laptop',
          sizes: [
            {
              maxWidth: 599,
              srcset:
                'https://pattern-lib-unimelb.netlify.app/campaign-hero-desktop.jpg',
            },
          ],
        },
        cta: {
          text: 'Register now',
          href: '#',
        },
      },
      pathfinderLinks: [
        {
          title: 'Undergraduate courses',
          description:
            'Flexible first degrees that you can tailor to your career goals, or explore new possibilities.',
          href: '/',
        },
        {
          title: 'Graduate courses',
          description:
            'Study in small classes led by experts alongside others who share your interests and goals.',
          href: '/',
        },
        {
          title: 'Graduate research',
          description:
            'Access dedicated research centres, advanced  facilities, generous scholarships and more.',
          href: '/',
        },
        {
          title: 'Short courses and microcredentials',
          description:
            'Upskill, expand your knowledge and take the next step in your career with focused courses.',
          href: '/',
        },
      ],
      sections: [
        {
          component: 'SectionAlt',
          type: 'video',
          heading: 'The Melbourne Curriculum',
          introduction:
            '<p>Follow your passions, get qualified, and become a creative and inter-disciplinary problem solver.</p>',
          cta: {
            text: 'Learn more about how our unique curriculum makes us different',
            link: '#',
          },
          video: {
            poster: {
              src: 'https://study.unimelb.edu.au/__data/assets/image/0030/268293/Screen-Shot-2021-05-24-at-1.53.17-pm.png',
            },
            video: {
              url: 'https://www.youtube.com/embed/sWHoKq6MSaE',
              youkuUrl: 'https://player.youku.com/embed/XNTE1ODM2MzcxMg',
              duration: 144,
            },
          },
        },
        {
          component: 'SectionAlt',
          type: 'columns',
          heading: 'Why study at Melbourne',
          introduction: '',
          cta: null,
          columns: [
            {
              heading: '#1 in Australia',
              content:
                '<p>We’re ranked #1 in Australia for teaching quality. You’ll be studying alongside exceptional teachers and students.</p>',
            },
            {
              heading: 'Globally recognised',
              content:
                '<p>We are a world-leading and globally-connected Australian university, with partnerships all around the world.</p>',
            },
            {
              heading: 'Learn from the best',
              content:
                '<p>Our academics include community, government and business experts, as well as Nobel Laureates and Rhodes Scholars.</p>',
            },
            {
              heading: 'Pursue your passions',
              content:
                '<p>A globally-aligned approach to education that allows you to follow your passions, get qualified, and become a creative and inter-disciplinary problem solver.</p>',
            },
          ],
        },
        {
          component: 'SectionImage',
          type: 'quick-links',
          heading: 'Get started with Melbourne',
          img: {
            src: 'https://via.placeholder.com/600',
            alt: 'Placeholder image',
          },
          links: [
            {
              title: 'Start your application',
              href: '#',
            },
            {
              title: 'Explore scholarships',
              href: '#',
            },
            {
              title: 'Find accommodation',
              href: '#',
            },
            {
              title: 'Alternate pathways to Melbourne University',
              href: '#',
            },
          ],
        },
        {
          component: 'SectionAlt',
          type: 'testimonials',
          heading: 'Hear from students',
          introduction:
            '<p>Follow your passions, get qualified, and become a creative and inter-disciplinary problem solver.</p>',
          testimonialType: 'video',
          testimonials: [
            {
              image_url:
                'https://www.brown.edu/sites/g/files/dprerj316/files/styles/portrait_sfts_desktop/public/Byrd_Advice.jpg?h=30f5acd3&itok=hcyHpFiN',
              name: 'Nigel Huang',
              title: 'What is it like studying online compared to on campus?',
              video: {
                url: 'https://www.youtube.com/embed/MH5pJ4jokpI',
                duration: 28,
                preview:
                  'https://player.vimeo.com/external/336863136.sd.mp4?s=234cbb2adf6f8c523093bc0627e7d8bdb697db94&amp;profile_id=165',
              },
            },
            {
              image_url:
                'https://www.brown.edu/sites/g/files/dprerj316/files/styles/portrait_sfts_desktop/public/Emerson_Advice.jpg?itok=rA76aBq9',
              name: 'Simi Chopra',
              title: 'Why did you choose a short course at Melbourne?',
              video: {
                url: 'https://www.youtube.com/embed/38RwlLDsNH4',
                duration: 27,
                preview:
                  'https://player.vimeo.com/external/356478966.sd.mp4?s=d29b0fc16e1c9411dcdda7a8543567896b725908&amp;profile_id=165',
              },
            },
          ],
        },
      ],
      testimonialVideoPlayingIndex: 0,
    };
  },
  async fetch({ store }) {
    await store.dispatch('setBreadcrumbs', [
      { text: this.title, href: this.basePath },
    ]);
  },
  head() {
    return {
      title: `Home ${this.$t('app.meta.title.suffix')}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Find a course at University of Melbourne. Search undergraduate degrees, graduate coursework, graduate research, study areas, majors or short courses.',
        },
        { name: 'study-type', content: 'Find a course home' },
        { name: 'page_type', content: 'fac' },
      ],
      bodyAttrs: {
        class: 'page-home-page',
      },
    };
  },
  computed: {
    isCampaign() {
      return this.$route.query && this.$route.query.campaign;
    },
  },
  meta: {
    pagetype: 'home',
  },
};
</script>
