<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="{
      'content-max-width': true,
      'shim-mb0': noMarginBottom,
    }"
    v-html="processedHTML"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script lang="ts">
import cheerio from 'cheerio';
import plugins from './markup-plugins.js';

/**
 * Based off https://github.com/dpc-sdp/ripple/tree/develop/packages/components/Organisms/Markup
 */
export default {
  props: {
    html: {
      type: String,
      required: false,
      default: '',
    },
    noMarginBottom: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    processedHTML() {
      const config = {};
      const html = `<div>${this.html}</div>`;
      const $ = cheerio.load(html, this.options);
      const $body = $('body');

      // Load plugins to transpile embedded components
      /* eslint-disable */
      for (const [index, plugin] of plugins.entries()) {
        $.prototype[`plugin${index}`] = plugin;
        $body[`plugin${index}`](config);
      }
      /* eslint-enable */

      return $('body div').html();
    },
  },
};
</script>
