/* eslint-disable */

/**
 * Add new helper classes to heading elements
 */
const headings = function () {
  this.find('h2, h3, h4, h5').map((i, element) => {
    const $el = this.find(element);
    const tagName = element.tagName.toLowerCase();
    const classMap = {
      h1: 'heading-lg',
      h2: 'heading-md',
      h3: 'heading-sm',
      h4: 'heading-xs',
      h5: 'heading-xxs',
    };

    return $el.addClass(classMap[tagName]);
  });
};

/**
 * Remove inline styles from CMS
 */
const inlineStyles = function () {
  this.find('p').map((i, element) => {
    const $el = this.find(element);
    return $el.attr('style', '');
  });
};

export default [headings, inlineStyles];
